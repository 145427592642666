import { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import DownloadButton from "./DownloadButton";
import Loader from "./Loader";

const Model = ({ data }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const { model } = useParams();

  useEffect(() => {
    setFilteredData(data);
    const groupData = data.filter((item) => item.nomenclature === model);
    setFilteredData(groupData);
    setLoading(false);
  }, [model]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="md:h-screen bg-gradient-to-b from-sky to-cream landscape:h-full">
          <div className="inline-flex pb-10">
            <Header />
            <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
          </div>

          {filteredData.map((commutator, index) => (
            <ul key={index}>
              <div className="flex justify-center">
                <div className="box-border h-4/5 w-4/5 md:inline-flex">
                  <div className=" md:w-1/4 md:pt-24 p-10">
                    <img src={commutator.image_products} alt="model"></img>
                  </div>

                  <div className="md:w-3/4 p-10 text-center font-Comfortaa text-blue landscape: pb-96">
                    <p className="font-bold ">{commutator.nomenclature}</p>
                    <p className="p-5 whitespace-pre-wrap text-justify list-disc">
                      {commutator.description}
                    </p>
                    <DownloadButton
                      url={commutator.documents}
                      download={commutator.nomenclature}
                      text="Скачать инструкцию"
                    />
                  </div>
                </div>
              </div>
            </ul>
          ))}
        </div>
      )}
    </>
  );
};
export default Model;
