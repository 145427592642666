import Header from "./Header.js";
import Menu from "./Menu.js";
import { useState, useEffect } from "react";
import board from "../imagies/board.avif";
import { Link, useParams } from "react-router-dom";
import Pagination from "./Pagination.js";
import Loader from "./Loader.js";
import axios from "axios";

const ListEquipment = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const { group } = useParams();


  useEffect(() => {
    axios
      .get("https://skynode.com.ru/api/?format=json")
      .then((response) => {        
       
        const groupData = response.data.filter((commutator) => commutator.group === group);
        setFilteredData(groupData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных", error);
      });
  }, [group]);

    
  // useEffect(() => {
  //    const groupData = data.filter((commutator) => commutator.group === group);
  //   setFilteredData(groupData);
  //   setLoading(false);
  // }, [group, Date.now()]);

  const text = filteredData.map(
    (item) => item.description.match(/^[^.]+\./)[0]
  );
  const firstSentence = filteredData.map((obj, index) => ({
    ...obj,
    text: text[index],
  }));

  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const preLastPage = Math.floor((filteredData.length / 5) % 10);
  let lastPage = preLastPage;

  if (preLastPage > 0) {
    lastPage = preLastPage + 1;
  } else {
    lastPage = preLastPage;
  }
  const currentItems = firstSentence.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (direction) => {
    if (
      direction === "forward" &&
      filteredData.length > currentPage * dataPerPage
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "backward" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="w-full h-full  bg-grey-blue page-transition ">
        <div className="inline-flex fixed ">
          <Header />
          <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="inline-flex ">

            <div className="border-white border-r pt-56 pl-16 pr-16 flex-none h-100% hidden lg:block">
              <div className="block flex-none">
              <img className="w-[305px]" src={board} alt="board "></img>

              <p className="font-Comfortaa text-white text-center font-bold pb-32">
                {group}
              </p>
              </div>
            </div>

            <div className="flex-col pt-32">
              {currentItems.map((commutator, index) => (
                <Link
                  className=" border-white  inline-flex border-b-2 w-full "
                  to={`/ListEquipment/${group}/${commutator.nomenclature}`}
                >
                  <ul key={index}>
                    <div className=" inline-flex items-center pt-7 pl-6 pb-7">
                      
                      <div className="flex-none box-border sm:w-1/2 md:w-1/5 md:h-1/3 pr-5">
                        <img
                          src={commutator.image_products}
                          alt={commutator.nomenclature}
                          className="w-1/2 h-full object-cover"
                        ></img>
                      </div>

                      <div className="font-Comfortaa text-white text-center pr-24 hover:scale-105">
                        <p className="p-5 sm:text-sm">
                          {commutator.nomenclature}
                        </p>
                        <p className="sm:text-sm">{commutator.text}</p>
                      </div>

                    </div>                    
                  </ul>
                </Link>
              ))}

              <div className=" flex justify-center pt-10 ">
                <button
                  className="text-[15px] text-center text-white font-Comfortaa mb-10 hover:scale-105 sm:mr-7"
                  onClick={() => handlePageChange("backward")}
                  disabled={currentPage === 1}
                >
                  Предыдущая
                </button>
                <Pagination
                  postsPerPage={dataPerPage}
                  totalPosts={filteredData.length}
                  paginate={paginate}
                />
                <button
                  className="text-[15px] text-center text-white font-Comfortaa sm:ml-7 md:ml-10 mb-10 hover:scale-105"
                  onClick={() => handlePageChange("forward")}
                  disabled={currentPage === lastPage}
                >
                  Следующая
                </button>
                {loading && <Loader />}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ListEquipment;
