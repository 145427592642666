import Header from "./Header.js";
import Footer from "./Footer.js";
import { useState } from "react";
import Menu from "./Menu.js";
import Logo from "./Logo.js";

const MainPage = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  return (
    <>
      <div className="h-screen  landscape:h-full bg-gradient-to-b from-sky to-cream">
        <div className="flex flex-auto flex-col 2xl:space-y-32 xl:space-y-20 md:space-y-52 sm:space-y-60">
          <div className="inline-flex ">
            <Header />
            <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
          </div>
          <Logo />
          <Footer />
        </div>
      </div>
    </>
  );
};
export default MainPage;
