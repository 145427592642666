import phone from "../imagies/phone.png";
import mail from "../imagies/mail.png";
import menu from "../imagies/icons8-menu-100.png";
import cross from "../imagies/cross.png";
import gitHub from "../imagies/github.png";
import whatsApp from "../imagies/whatsapp.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
const Menu = ({ active, setActiveMenu }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://skynode.com.ru/api/?format=json")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных", error);
      });
  }, []);

  function groupBy(array, key1) {
    return array.reduce((acc, item) => {
      const groupKey = item[key1];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    }, {});
  }
  const groupedItems = groupBy(data, "group");

  const handleActiveMenu = () => {
    setActiveMenu(true);
  };
  const handleExitMenu = () => {
    setActiveMenu(false);
  };
console.log(data)
  return (
    <>
      {active === true ? (
        <div className="fixed inset-0 bg-[rgba (0,0,0,0.3)] backdrop-blur-sm  z-10 ">
          <div className="lg:w-[600px] sm:w-full  h-screen fixed bg-cream opacity-80 shadow-xl right-0 font-Comfortaa transition-[0.5s] duration-700 text-sky font-bold lg:text-base sm:text-xs">
            <div className="flex flex-col m-12 ">
              <div className="flex flex-row justify-between border-b  border-sky mb-16">
                <Link
                  className="hover:scale-105"
                  to="/AllProducts"
                  onClick={handleExitMenu}
                >
                  Товары
                </Link>
                <Link className="hover:scale-105"to="/About">
                  О нас
                </Link>
                <Link className="hover:scale-105"to="/Contacts">Контакты</Link>
                <button onClick={handleExitMenu}>
                  <img src={cross} alt="cross" className="lg:w-10 sm:w-5" />
                </button>
              </div>
              <div className="mb-16">
                {Object.keys(groupedItems).map((group, index) => (
                  <Link to={`/ListEquipment/${group}`}>
                    <ul className="pt-8" key={index}>
                      {group}
                    </ul>
                  </Link>
                ))}
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pt-10">
                <span>ИНН 7203556332</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide">
                <span>Юридический адрес: г.Тюмень, ул.Котельщиков, д. 2</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pt-10 pb-2">
                <img src={whatsApp} alt="whatsApp" className=" w-5 h-5" />
                <span className="pl-4">+7 (912) 077-50-63</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pb-2">
                <img src={phone} alt="phone" className=" w-5 h-5" />
                <span className="pl-4">+7 (3452) 38-15-73</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs  tracking-wide pb-12">
                <img src={mail} alt="mail" className=" w-5 h-5" />
                <button
                  onClick={() => {
                    window.open("mailto:skn@skynode.com.ru");
                  }}
                  className="hover:scale-105 underline pl-3"
                >
                  skn@skynode.com.ru
                </button>
              </div>
              <div className="flex flex-row justify-between lg:text-xs sm:text-[10px]">
                <span>
                  Ⓒ2024 SKYNODE<sup>TM</sup> | Desined by Anutacom |Beta-version
                </span>
                <Link to="https://github.com/anutacom">
                  <img src={gitHub} alt="gitHub"></img>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="lg:w-[600px] sm:w-full fixed bg-cream opacity-80 shadow-xl right-0 h-screen font-Comfortaa transition-[0.5s] translate-x-full duration-700 text-sky font-bold lg:text-base sm:text-xs">
            <div className="flex flex-col m-12 ">
              <div className="flex flex-row justify-between border-b  border-sky mb-16">
                <Link
                  title="Раздел в разработке"
                  to="/ListEquipment"
                  onClick={handleExitMenu}
                >
                  Товары
                </Link>
                <Link title="Раздел в разработке">О нас</Link>
                <Link title="Раздел в разработке">Контакты</Link>
                <button onClick={handleExitMenu}>
                  <img src={cross} alt="cross" className="lg:w-10 sm:w-5" />
                </button>
              </div>
              <div className="mb-16">
                {Object.keys(groupedItems).map((group, index) => (
                  <Link to={`/ListEquipment/${group}`}>
                    <ul className="pt-8" key={index}>
                      {group}
                    </ul>
                  </Link>
                ))}
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pt-10 ">
                <span>ИНН 7203556332</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide">
                <span>Юридический адрес: г.Тюмень, ул.Котельщиков, д. 2</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pt-10 pb-2">
                <img src={whatsApp} alt="whatsApp" className=" w-5 h-5" />
                <span className="pl-4">+7 (912) 077-50-63</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs tracking-wide pb-2">
                <img src={phone} alt="phone" className=" w-5 h-5" />
                <span className="pl-4">+7 (3452) 38-15-73</span>
              </div>
              <div className="flex flex-row lg:text-sm sm:text-xs  tracking-wide pb-12">
                <img src={mail} alt="mail" className=" w-5 h-5" />
                <button
                  onClick={() => {
                    window.open("mailto:skn@skynode.com.ru");
                  }}
                  className="hover:scale-105 underline pl-3"
                >
                  skn@skynode.com.ru
                </button>
              </div>
              <div className="flex flex-row justify-between lg:text-xs sm:text-[10px]">
                <span>
                  Ⓒ2024 SKYNODE<sup>TM</sup> | Desined by Anutacom |Beta-version
                </span>
                <Link to="https://github.com/anutacom">
                  <img src={gitHub} alt="gitHub"></img>
                </Link>
              </div>
            </div>
          </div>{" "}
          <button
            onClick={handleActiveMenu}
            className="fixed lg:right-32 sm:right-5 lg:pt-7 sm:pt-5 lg:w-16 sm:w-10"
          >
            <img src={menu} alt="menu"></img>
          </button>
        </div>
      )}
    </>
  );
};
export default Menu;
