import React from 'react';

function DownloadButton({ filename, url, text }) {
  return (
    <a href={url} download={filename}>
      <p className='font-bold hover:scale-110'>{text}</p>
    </a>
  );
}

export default DownloadButton;