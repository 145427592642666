import Header from "./Header";
import card from "../imagies/Card.avif";
import document1 from "../imagies/Declaration from  15.06.2023.avif";
import document2 from "../imagies/Declaration from 14.12.2023.avif";
import document3 from "../imagies/Document logo.avif";
import doc1 from "../data/ДC от 15.06.2023.pdf"
import doc2 from "../data/ДС от 14.12.2023.pdf"
import card1 from "../data/Карточка предприятия Сетевые Технологии.pdf"
import Menu from "./Menu.js";
import { useState } from "react";

import DownloadButton from "./DownloadButton";

const About = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  return (
    <>
      <div className="h-screen bg-gradient-to-b from-sky to-cream ">
        <div className=" inline-flex pb-10">
        <Header />
            <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
        </div>

        <div className="grid grid-cols-4 justify-items-center">
          <div className="block object-cover">
            <img className="opacity-60 pb-5" src={document1} alt="decl1"></img>
            <div className="text-center font-Comfortaa text-blue text-[10px] md:text-lg">
              <DownloadButton
                url={doc1}
                filename="ДC от 15.06.2023.pdf"
                text="Скачать ДоC от 15.06.2023"
              />
            </div>
          </div>

          <div className=" block object-cover">
            <img className="opacity-60 pb-5" src={document2} alt="decl2"></img>
            <div className="text-center font-Comfortaa text-blue text-[10px] md:text-lg">
              <DownloadButton
                url={doc2}
                filename="ДС от 14.12.2023.pdf"
                text="Скачать ДоС от 14.12.2023"
              />
            </div>
          </div>

          <div className=" block object-cover">
            <img className="opacity-60 pb-5" src={card} alt="card"></img>
            <div className="text-center font-Comfortaa text-blue text-[10px] md:text-lg">
              <DownloadButton
                url={card1}
                filename="Карточка предприятия Сетевые Технологии.pdf"
                text="Скачать карточку предприятия"
              />
            </div>
          </div>
          <div className=" block object-cover opacity-60">
            <img src={document3} alt="docLogo"></img>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
