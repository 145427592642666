import Header from "./Header";
import Menu from "./Menu.js";
import { useState } from "react";

const Contacts = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  return (
    <>
      <div className="h-screen bg-gradient-to-b from-sky to-cream ">
        <div className=" inline-flex pb-20">
          <Header />
          <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
        </div>
        <div className="">
          <p className="text-center font-bold font-Comfortaa text-blue leading-10">
            <p className="block">
              Общество с ограниченной ответственностью "Сетевые технологии"
            </p>
            <p className="block">ИНН 7203556332</p>
            <p className="block">КПП 720301001</p>
            <p className="block">Телефон: +7 (3452) 38-15-73</p>
            <p className="block">WhatsApp: +7 (912) 077-50-63</p>
            <p className="block">
              Информация о наличии и подбору оборудования :
              <button
                onClick={() => {
                  window.open("mailto:skn@skynode.com.ru");
                }}
                className="hover:scale-105 underline"
              >
                skn@skynode.com.ru
              </button>
            </p>{" "}
          </p>
        </div>
      </div>
    </>
  );
};
export default Contacts;
