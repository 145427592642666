import vectorLogo from "../imagies/Vector-logo.png";

const Logo = () => {
  return (
    <>
      <div className="my-logo flex justify-center">
        <img src={vectorLogo} alt="vectorLogo" className="w-3/5 2xl:w-1/2 xl:3/5 md:w-5/6 lg:h-3/5" ></img>
      </div>
    </>
  );
};
export default Logo;
