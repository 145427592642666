import { useState, useEffect } from "react";

function Footer() {
  const [footerActive, setFooterActive] = useState(false);

  useEffect(() => {
    setFooterActive(true);
  });
  return (
    <>
      {footerActive === true ? (
        <div className="w-full  lg:pl-24 sm:pl-10 transition-[0.5s] duration-700 font-Comfortaa font-bold text-sky lg:text-xs sm:text-[10px] md:pb-96">
          Ⓒ2024 SKYNODE<sup>TM</sup> | Desined by Anutacom | Beta-version
        </div>
      ) : (
        <div className="w-full h-screen lg:pl-24 sm:pl-10 transition-[0.5s] duration-700 translate-x-full font-Comfortaa font-bold text-sky lg:text-xs sm:text-[10px] md:pb-96">
          Ⓒ2024 SKYNODE<sup>TM</sup> | Desined by Anutacom | Beta-version
        </div>
      )}
    </>
  );
}
export default Footer;
