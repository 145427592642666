import logoSky from "../imagies/logoSky.png";
import { Link } from "react-router-dom";


const Header = () => {
  return (
    <>
      <div className="w-full flex flex-row ">
       <Link to="/">
        <img src={logoSky} alt="logo" className=" xl:h-28 md:h-24 sm:h-20 xl:pl-32 sm:pl-10 pt-2.5 " />
        </Link>
      </div>
    </>
  );
};
export default Header;
