import Header from "./Header.js";
import Menu from "./Menu.js";
import { useState } from "react";
import board from "../imagies/board.avif";
import { Link } from "react-router-dom";
import Pagination from "./Pagination.js";
import Loader from "./Loader.js";

const AllProducts = ({ data }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);

  const text = data.map((item) => item.description.match(/^[^.]+\./)[0]);
  const firstSentence = data.map((obj, index) => ({
    ...obj,
    text: text[index],
  }));

  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  
  const preLastPage = Math.floor((data.length / 5) % 10);
  let lastPage = preLastPage;

  if (preLastPage > 0) {
    lastPage = preLastPage + 1;
  } else {
    lastPage = preLastPage;
  }
  const currentItems = firstSentence.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (direction) => {
    if (
      direction === "forward" &&
      data.length > currentPage * dataPerPage
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "backward" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="w-full h-full  bg-grey-blue page-transition ">
        <div className="inline-flex fixed ">
          <Header />
          <Menu active={activeMenu} setActiveMenu={setActiveMenu} />
        </div>

        <div className="inline-flex ">

          <div className="border-white border-r pt-56 pl-16 pr-16 flex-none h-100% hidden lg:block">

            <div className="block flex-none">
              <img className="w-[305px]" src={board} alt="board "></img>
            </div>

            <p className="font-Comfortaa text-white text-center font-bold pb-32">Все товары</p>
          </div>

          <div className="flex-col pt-32">
            {loading ? (
              <Loader/>
            ) : (
              currentItems.map((commutator, index) => (
                <Link
                  className=" border-white  inline-flex border-b-2 w-full "
                  to={`/AllProducts/${commutator.nomenclature}`}
                >
                  <ul key={index}>
                    <div className=" inline-flex items-center pt-7 pl-6 pb-7">
                      <img
                        className="w-1/5 pr-10"
                        src={commutator.image_products}
                        alt={commutator.nomenclature}
                      ></img>

                      <div className="font-Comfortaa text-white text-center pr-24">
                        <p className="p-5">{commutator.nomenclature}</p>
                        <p>{commutator.text}</p>
                      </div>
                    </div>
                  </ul>
                </Link>
              ))
            )}
          </div>
        </div>
        
        <div className=" flex justify-center pt-10 ">
          <button
            className="text-[15px] text-center text-white font-Comfortaa mb-10"
            onClick={() => handlePageChange("backward")}
            disabled={currentPage === 1}
          >
            Предыдущая
          </button>
          <Pagination
            postsPerPage={dataPerPage}
            totalPosts={data.length}
            paginate={paginate}
          />
          <button
            className="text-[15px] text-center text-white font-Comfortaa ml-10 mb-10"
            onClick={() => handlePageChange("forward")}
            disabled={currentPage === lastPage}
          >
            Следующая
          </button>
        </div>

      </div>
    </>
  );
};
export default AllProducts;
