import AllProducts from "./components/AllProducts.js";
import ListEquipment from "./components/ListEquipment.js";
import Model from "./components/Model.js";
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import MainPage from "./components/MainPage.js";
import About from "./components/About.js";
import Contacts from "./components/Contacts.js";

function App() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://skynode.com.ru/api/?format=json")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных", error);
      });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route
          path="/ListEquipment/:group"
          element={<ListEquipment/>}
        />
        <Route
          path="/ListEquipment/:group/:model"
          element={<Model data={data} />}
        />
        <Route path="/AllProducts/" element={<AllProducts data={data} />} />
        <Route path="/AllProducts/:model" element={<Model data={data} />} />
        <Route path="/About/" element={<About />} />
        <Route path="/Contacts/" element={<Contacts />} />
      </Routes>
    </>
  );
}

export default App;
