function Pagination({ postsPerPage, totalPosts, paginate }) {  
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div>
      <ul>
        {pageNumbers.map((number) => (
          <li  className="inline-flex text-[15px] text-center text-white font-Comfortaa mb-10 lg:ml-10 sm:text-sm sm:ml-4 hover:scale-105" key={number}>
            <button onClick={() => paginate(number)}>
              <p className = "hover:scale-150">{number}</p>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Pagination;
